import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: theme.typography.h3.fontSize
  },
  buttonLabel: {
    justifyContent: 'space-between',
    textTransform: 'none'
  },
  userAddress: {
    all: 'unset',
    width: '100%',
    color: theme.palette.text.primary,
    ...theme.typography.body1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  dialogPaper: {
    margin: 0,
    width: '100%'
  }
}));
