import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core';
import React from 'react';
import { TestForm } from '../test-form/TestForm';

export const LotteryLayout = () => {
  const theme = useTheme();

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: `${theme.spacing(8)}px 0`,
          paddingBottom: theme.spacing(4),
          background:
            'linear-gradient(159deg, rgba(249,255,108,1) 6%, rgba(255,182,50,1) 66%)'
        }}
      >
        <Container maxWidth={'lg'}>
          <Typography
            component="h1"
            style={{ fontWeight: 500, fontSize: theme.spacing(6) }}
          >
            Lottery
          </Typography>
          <Typography component="h2" variant="h3" style={{ fontWeight: 500 }}>
            Test your luck with the new RUGME lottery!
          </Typography>
          <Grid
            container
            justify="space-between"
            spacing={3}
            style={{ marginTop: theme.spacing(3) }}
          >
            <Grid item xs={12} sm={4} lg={3} xl={3}>
              <Card elevation={5}>
                <CardContent>
                  <Typography color="textPrimary" variant="h3">
                    Payout
                  </Typography>
                  <ul style={{ marginLeft: theme.spacing(3) }}>
                    <Typography
                      component="li"
                      variant="h5"
                      style={{ fontWeight: 500 }}
                    >
                      1/16 chance of 10x
                    </Typography>
                    <Typography
                      component="li"
                      variant="h5"
                      style={{ fontWeight: 500 }}
                    >
                      1/256 chance of 100x
                    </Typography>
                    <Typography
                      component="li"
                      variant="h5"
                      style={{ fontWeight: 500 }}
                    >
                      1/4096 chance of Jackpot
                    </Typography>
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container
        maxWidth={'lg'}
        style={{
          margin: `${theme.spacing(3)}px auto`,
          height: '100%',
          paddingBottom: theme.spacing(3)
        }}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <TestForm />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
