import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GlobalAppContext } from 'src/store/global-app-context';
import { useStyles } from './TestForm.styles';

export const TestForm = () => {
  const [amount, setAmount] = useState<string>('0');

  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const classes = useStyles();
  const intervalRef = useRef<any>();

  const [display, setDisplay] = useState<
    | 'WON_DISPLAY'
    | 'NO_WIN_DISPLAY'
    | 'CHECK_IF_WON_DISPLAY'
    | 'LOADING_DISPLAY'
    | ''
  >('LOADING_DISPLAY');

  const { enqueueSnackbar } = useSnackbar();

  const { state } = useContext(GlobalAppContext);

  useEffect(() => {
    checkIfBlocksHavePassed();

    if (!intervalRef.current) {
      // Check every 5 seconds
      intervalRef.current = setInterval(async () => {
        checkIfBlocksHavePassed();
      }, 5000);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const checkIfBlocksHavePassed = async () => {
    const currentBlock = await getCurrentBlockNumber();
    const endingUserBlock = await getUserVestEnd();

    if (currentBlock >= endingUserBlock) {
      clearInterval(intervalRef.current);
      // If a user has never played before, their ending block will be 0
      if (Number(endingUserBlock) > 0) {
        setDisplay('CHECK_IF_WON_DISPLAY');
      } else {
        setDisplay('');
      }
    } else {
      setDisplay('LOADING_DISPLAY');
    }
  };

  const getUserVestEnd = async () => {
    const userEntry = await state.lotteryInstance.methods
      .entry(state.userAddress)
      .call();

    return userEntry.vestEnd;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const web3 = (window as any).web3;
    const convertedAmount = web3.utils.toWei(amount as string, 'Ether');

    return state.lotteryInstance.methods
      .submitEntry('1')
      .send({ value: convertedAmount, from: state.userAddress })
      .then(async (result: any) => {
        setLoading(false);
        setAmount('0');

        enqueueSnackbar(`Successfully deposited ${amount}. Good luck!`, {
          variant: 'success'
        });
        setDisplay('LOADING_DISPLAY');

        // Check every 5 seconds
        intervalRef.current = setInterval(async () => {
          checkIfBlocksHavePassed();
        }, 5000);
      })
      .catch((err: any) => {
        setLoading(false);
        enqueueSnackbar(err.message ? err.message : err, {
          variant: 'error'
        });
      });
  };

  const getCurrentBlockNumber = async () => {
    const web3 = (window as any).web3;
    return await web3.eth.getBlockNumber();
  };

  const checkIfWon = async () => {
    return await state.lotteryInstance.methods.claimEntry().estimateGas(
      {
        from: state.userAddress,
        gasPrice: '12000000000' //can make 12000000000 I think it shouldn't really matter
      },
      (error: any, estimatedGas: any) => {
        if (error) {
          //NO WIN :(
          setDisplay('NO_WIN_DISPLAY');
        } else {
          //YES WIN :)
          setDisplay('WON_DISPLAY');

          claimEntry();
        }
      }
    );
  };

  const claimEntry = async () => {
    return await state.lotteryInstance.methods
      .claimEntry()
      .send({ from: state.userAddress });
  };

  const changeAmount = (event: any) => {
    setAmount(event.target.value);
  };

  const formDisplay = () => (
    <Card elevation={5} style={{ maxWidth: 400 }}>
      {loading && (
        <LinearProgress
          classes={{ root: classes.linearProgress }}
          color="primary"
        />
      )}
      <CardContent>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Amount"
            disabled={loading}
            color="primary"
            type="number"
            inputProps={{
              type: 'number',
              inputMode: 'decimal'
            }}
            variant="outlined"
            onChange={changeAmount}
            style={{ marginBottom: theme.spacing(2) }}
            value={amount}
          />
          {/* <TextField label="receiver" onChange={changeReceiver} value={receiver} /> */}
          <Button
            variant="contained"
            disabled={loading || Number(amount) <= 0}
            onClick={handleSubmit}
            color="primary"
          >
            Play
          </Button>
        </div>
      </CardContent>
    </Card>
  );

  const checkIfWonDisplay = () => (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      onClick={checkIfWon}
    >
      Check if won
    </Button>
  );

  const loadingDisplay = () => (
    <Grid container direction="column" justify="center" alignItems="center">
      <CircularProgress color="primary" />{' '}
      <Typography color="textPrimary" variant="h2">
        Please wait... Do not refresh the page
      </Typography>
    </Grid>
  );

  const noWinDisplay = () => (
    <>
      <Typography
        color="textPrimary"
        variant="h2"
        style={{ marginBottom: theme.spacing(2) }}
      >
        Sorry you did not win. Play again!
      </Typography>
      {formDisplay()}
    </>
  );
  const wonDisplay = () => (
    <>
      <Typography
        color="textPrimary"
        variant="h2"
        style={{ marginBottom: theme.spacing(2) }}
      >
        Congrats! You Won! Claim your prize and play again!
      </Typography>
      {formDisplay()}
    </>
  );

  const renderDisplay = () => {
    switch (display) {
      case '':
        return formDisplay();

      case 'CHECK_IF_WON_DISPLAY':
        return checkIfWonDisplay();
      case 'LOADING_DISPLAY':
        return loadingDisplay();
      case 'NO_WIN_DISPLAY':
        return noWinDisplay();
      case 'WON_DISPLAY':
        return wonDisplay();

      default:
        return formDisplay();
    }
  };

  return <>{renderDisplay()}</>;
};
