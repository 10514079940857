import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Helmet } from 'react-helmet';
import { DiscordIcon } from 'src/assets/icons/Discord';
import { FarmCard } from 'src/components/home/FarmCard';
import { LotteryCard } from 'src/components/home/LotteryCard';
import { Logo } from 'src/components/Logo';
import './styles.css';

export const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  const _description =
    "Welcome to Rugme Finance! The world's first Rug Pull platform made for Rug Pull victims.";

  return (
    <>
      <Helmet>
        <title>Home | Rugme.finance</title>
        <meta name="description" content={_description} />
      </Helmet>
      <div
        style={{
          background:
            'linear-gradient(-45deg, #FFB632, #ebab34, #e67e07, #C6312E)',
          backgroundSize: '400% 400%',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          animation: 'gradient 10s ease infinite',
          zIndex: 1
        }}
      ></div>
      <Box
        style={{
          position: 'relative',
          top: 0,
          left: 0,

          zIndex: 2,
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <Container maxWidth="lg">
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: `${theme.spacing(6)}px ${theme.spacing(3)}px`
            }}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              direction={isMobile ? 'column' : 'row'}
            >
              <Logo
                style={{
                  height: theme.spacing(20),
                  marginRight: isMobile ? 0 : theme.spacing(4),
                  filter: 'drop-shadow(4px 4px 10px #000000)'
                }}
              />
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify={isMobile ? 'center' : 'flex-start'}
                  alignItems={isMobile ? 'center' : 'flex-start'}
                >
                  <Typography
                    variant="h1"
                    component="h1"
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      textShadow: '2px 2px 4px #000000'
                    }}
                  >
                    Welcome to
                  </Typography>
                  <Typography
                    color="primary"
                    component="h1"
                    style={{
                      textAlign: 'center',
                      textShadow: '2px 2px 4px #000000',
                      fontSize: theme.spacing(6),
                      lineHeight: '50px'
                    }}
                  >
                    Rugme Finance
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Typography
              variant="h4"
              component="h4"
              style={{
                color: 'white',
                textAlign: 'center',
                textShadow: '2px 2px 4px #000000',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),

              }}
            >
              The world's first Rug Pull platform made for Rug Pull victims.
            </Typography>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  color="primary"
                  href={'https://www.inklewriter.com/stories/70275'}
                  target="_blank"
                  variant="contained"
                >
                  White Paper
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: theme.spacing(3) }}
            >
              <IconButton
                href={'https://twitter.com/rugmefinance'}
                target="_blank"
                color="default"
              >
                <TwitterIcon style={{ color: 'white' }} />
              </IconButton>

              <IconButton color="default">
                <DiscordIcon
                  href={'https://discord.gg/yjtY8Q96qC'}
                  target="_blank"
                  style={{ color: 'white' }}
                />
              </IconButton>
            </Grid>
          </Box>

          <Grid container spacing={3} justify="space-evenly">
            <Grid item xs={12} sm={5} lg={4} xl={4}>
              <FarmCard />
            </Grid>
            <Grid item xs={12} sm={5} lg={4} xl={4}>
              <LotteryCard />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
