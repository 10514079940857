import { Box, Container, Link, Typography, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';

export const Documentation = () => {
  const theme = useTheme();

  const _description = 'It gets better.';
  return (
    <>
      <Helmet>
        <title>Documentation | Rugme.finance</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Box
        style={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          marginTop: theme.spacing(6)
        }}
      >
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h1"
            color="textPrimary"
            style={{ marginBottom: theme.spacing(3) }}
          >
            Documentation
          </Typography>
          <Typography component="h2" variant="h2" color="textPrimary">
            White Paper
          </Typography>
          <Typography component="p" variant="body1" color="textPrimary">
            - You can view our white paper{' '}
            <Link
              href={'https://www.inklewriter.com/stories/70275'}
              target="_blank"
            >
              here
            </Link>
            .
          </Typography>
          <Box style={{ textAlign: 'center' }}>
            <img
              alt="Not Found"
              src="/static/images/it_gets_better.jpg"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 560
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};
