import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink as RouterLink } from 'react-router-dom';

export const LotteryCard = (props: any) => {
  return (
    <Card style={{ height: '100%' }} {...props} elevation={5}>
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'space-between', flexDirection: 'column' }}
        >
          <Grid item>
            <Typography color="textPrimary" gutterBottom variant="h3">
              RUGME Lottery is Live
            </Typography>
            <Typography color="textPrimary" gutterBottom variant="h4">
              Test Your Luck With Our New RUGME Lottery.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component={RouterLink}
              color="primary"
              variant="contained"
              to={'/lottery'}
              endIcon={<ArrowForwardIcon />}
            >
              Go To Lottery
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
