import {
  IconButton,
  makeStyles,
  SwipeableDrawer,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Logo } from '../Logo';
import { NavRoutes } from './NavRoutes';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary
  },
  listItem: {
    width: 250
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  logo: {
    height: theme.spacing(6)
  },
  hide: {
    display: 'none'
  },

  navLinksContainer: {},
  navLink: {},
  logoContainer: {
    display: 'flex',
    flexGrow: 1
  }
}));

export const NavContent: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMobileDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      )}
      <div className={classes.logoContainer}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
      </div>

      <SwipeableDrawer
        anchor="left"
        open={mobileDrawerOpen && isMobile}
        onClose={() => setMobileDrawerOpen(false)}
        onOpen={() => setMobileDrawerOpen(true)}
      >
        <div className={classes.closeButtonContainer}>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={() => setMobileDrawerOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <NavRoutes onClick={() => setMobileDrawerOpen(false)} />
      </SwipeableDrawer>
    </>
  );
};
