import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import Lottery from 'src/assets/builds/Lottery.json';
import MasterRug from 'src/assets/builds/MasterRug.json';
import RugMe from 'src/assets/builds/RugMe.json';
import { MetaMaskIcon } from 'src/assets/icons/MetaMask/MetaMask';
import { GlobalAppContext } from 'src/store/global-app-context';
import { ActionTypes } from 'src/store/reducer';
import Web3 from 'web3';
import { useStyles } from './WalletDialog.styles';

export const WalletDialog = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useContext(GlobalAppContext);

  const [connectWalletOpen, setConnectWalletOpen] = useState(false);
  const [walletAlreadyConnected, setWalletAlreadyConnected] = useState(false);

  useEffect(() => {
    if (state.showConnectWalletDialog && !state.isWalletConnected) {
      setConnectWalletOpen(true);
      setWalletAlreadyConnected(false);
    } else if (state.showConnectWalletDialog && state.isWalletConnected) {
      setConnectWalletOpen(false);
      setWalletAlreadyConnected(true);
    }
  }, [state.showConnectWalletDialog, state.isWalletConnected]);

  useEffect(() => {
    if (state.rugInstance && state.masterRugInstance) {
      const web3 = (window as any).web3;
      web3.eth
        .getAccounts()
        .then((accounts: any) => {
          dispatch({
            type: ActionTypes.SET_USER_ADDRESS,
            payload: accounts[0]
          });
          dispatch({
            type: ActionTypes.SET_IS_WALLET_CONNECTED,
            payload: true
          });
          enqueueSnackbar('Wallet connected!', { variant: 'success' });
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message ? err.message : err, {
            variant: 'error'
          });
        });
    }
  }, [state.rugInstance, state.masterRugInstance]);

  const handleClose = () => {
    dispatch({
      type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG,
      payload: false
    });

    setWalletAlreadyConnected(false);
    setConnectWalletOpen(false);
  };
  const loadBlockchainData = async () => {
    const web3 = (window as any).web3;
    // Load netId
    web3.eth.net
      .getId()
      .then(async (netId: any) => {
        // Load RugMe.json
        const rugMeData = (RugMe as any).networks[netId];
        if (rugMeData) {
          const rugMeContract = await new web3.eth.Contract(
            RugMe.abi,
            (rugMeData as any).address
          );

          dispatch({
            type: ActionTypes.SET_RUG_INSTANCE,
            payload: rugMeContract
          });
        } else {
          enqueueSnackbar(
            'Error loading RugMe blockchain data. Check wallet network',
            {
              variant: 'error'
            }
          );
        }

        // Load MasterRugData
        const MasterRugData = (MasterRug as any).networks[netId];
        if (MasterRugData) {
          const masterRugContract = await new web3.eth.Contract(
            MasterRug.abi,
            (MasterRugData as any).address
          );

          dispatch({
            type: ActionTypes.SET_MASTER_RUG_INSTANCE,
            payload: masterRugContract
          });
        } else {
          enqueueSnackbar('Error loading MasterRugData. Check wallet network', {
            variant: 'error'
          });
        }

        // LoadLottery
        const LotteryData = (Lottery as any).networks[netId];
        if (LotteryData) {
          const LotteryInstance = await new web3.eth.Contract(
            Lottery.abi,
            (LotteryData as any).address
          );

          dispatch({
            type: ActionTypes.SET_LOTTERY_INSTANCE,
            payload: LotteryInstance
          });
        } else {
          enqueueSnackbar('Error loading LotteryData. Check wallet network', {
            variant: 'error'
          });
        }
      })
      .catch((err: any) => {
        enqueueSnackbar(err.message ? err.message : err, { variant: 'error' });
      });
  };

  const connect = async () => {
    if ((window as any).ethereum) {
      (window as any).web3 = new Web3((window as any).ethereum);
      (window as any).ethereum
        .enable()
        .then(async () => {
          await loadBlockchainData();
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message ? err.message : err, {
            variant: 'error'
          });
        });
    } else if ((window as any).web3) {
      (window as any).web3 = new Web3((window as any).web3.currentProvider);
    } else {
      enqueueSnackbar(
        'Non-Ethereum browser detected. You should consider using MetaMask!',
        { variant: 'error' }
      );
    }
  };

  const logout = () => {
    dispatch({
      type: ActionTypes.SET_IS_WALLET_CONNECTED,
      payload: false
    });
    dispatch({
      type: ActionTypes.SET_RUG_INSTANCE,
      payload: null
    });
    dispatch({
      type: ActionTypes.SET_MASTER_RUG_INSTANCE,
      payload: null
    });
    dispatch({
      type: ActionTypes.SET_USER_ADDRESS,
      payload: null
    });
    enqueueSnackbar('Successfully logged out!', { variant: 'success' });
  };

  const copyAddress = () => {
    const copyText = document.getElementById('wallet-address') as any;

    if (copyText) {
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy');

      /* Alert the copied text */
      enqueueSnackbar('Copied!', { variant: 'success' });
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={connectWalletOpen}
        onClose={handleClose}
        aria-labelledby="connect-wallet"
      >
        <DialogTitle className={classes.dialogTitleContainer} disableTypography>
          <Typography variant="h4" color="textPrimary" component="p">
            Connect a Wallet
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={connect}
            classes={{ label: classes.buttonLabel }}
            endIcon={<MetaMaskIcon />}
          >
            MetaMask
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={walletAlreadyConnected}
        onClose={handleClose}
        aria-labelledby="wallet-connected"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.dialogTitleContainer} disableTypography>
          <Typography variant="h4" color="textPrimary" component="p">
            Your wallet
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {state.userAddress && (
          <>
            <DialogContent dividers>
              <input
                value={state.userAddress}
                className={classes.userAddress}
                readOnly
                id="wallet-address"
              />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: theme.spacing(2)
                }}
              >
                <Button
                  color="primary"
                  href={`https://bscscan.com/address/${state.userAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<LaunchIcon />}
                  variant="outlined"
                >
                  View on BscScan
                </Button>
                <Button
                  onClick={copyAddress}
                  color="primary"
                  variant="outlined"
                  endIcon={<FileCopyIcon />}
                  style={{ marginLeft: theme.spacing(3) }}
                >
                  Copy Address
                </Button>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={logout}
                color="primary"
                variant="outlined"
                endIcon={<ExitToAppIcon />}
              >
                Logout
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
