import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink as RouterLink } from 'react-router-dom';

export const FarmCard = (props: any) => {
  return (
    <Card style={{ height: '100%' }} {...props} elevation={5}>
      <CardContent>
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: 'space-between',

            flexDirection: 'column'
          }}
        >
          <Grid item>
            <Typography color="textPrimary" gutterBottom variant="h3">
              Farming Now Available!
            </Typography>
            <Typography color="textPrimary" variant="h4">
              Deposit LP Tokens and Earn With RUGME Farms!
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component={RouterLink}
              color="primary"
              variant="contained"
              to={'/farm'}
              endIcon={<ArrowForwardIcon />}
            >
              Try Now
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
