import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  column: {
    flexBasis: '33.3%',
    minWidth: 80
  },
  accordionSummaryContent: {
    display: 'flex'
  },
  actionsContainer: {
    flexGrow: 1,
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    margin: theme.spacing(2)
  },
  linearProgress: {
    borderRadius: 'inherit',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  accordionRoot: {
    background:
      'linear-gradient(159deg, rgba(249,255,108,1) 6%, rgba(255,182,50,1) 66%)'
  }
}));
