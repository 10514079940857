import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { GlobalAppContext } from 'src/store/global-app-context';
import { ActionTypes } from 'src/store/reducer';
import { NavContent } from './NavContent';
import { NavRoutes } from './NavRoutes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    whiteSpace: 'nowrap'
  },
  desktopDrawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    width: '100%',
    flexGrow: 1
  },
  toggleDarkMode: {
    cursor: 'pointer',
    marginRight: theme.spacing(3)
  },
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',

    justifyContent: 'space-between'
  },
  buttonLabel: {
    justifyContent: 'space-between',
    textTransform: 'none'
  }
}));

interface IProps {
  children: any;
}

export const MainNavbar: React.FC<IProps> = ({ children }) => {
  const theme = useTheme();
  const { state, dispatch } = useContext(GlobalAppContext);
  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  const classes = useStyles();

  const [desktopDrawerOpen, setDrawerOpen] = useState(false);

  const handleDarkModeChange = (payload: boolean) => {
    dispatch({
      type: ActionTypes.SET_IS_DARK_MODE,
      payload
    });
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="default"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: desktopDrawerOpen && !isMobile
        })}
      >
        <Toolbar className={classes.toolbar}>
          {!isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(true)}
              className={clsx(classes.menuButton, {
                [classes.hide]: desktopDrawerOpen
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          <NavContent />

          {/* Toggle dark mode */}
          <Typography
            color="textSecondary"
            variant="h1"
            component="p"
            className={classes.toggleDarkMode}
            onClick={() => handleDarkModeChange(!state.isDarkMode)}
          >
            {state.isDarkMode ? <>🌝</> : <>🌚</>}
          </Typography>

          {/* Connect wallet button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({
                type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG,
                payload: true
              });
            }}
            classes={{ label: classes.buttonLabel }}
          >
            {state.isWalletConnected
              ? state.userAddress?.slice(0, 4) +
                '...' +
                state.userAddress?.slice(
                  state.userAddress.length - 4,
                  state.userAddress.length
                )
              : 'Connect'}
          </Button>
        </Toolbar>
      </AppBar>

      {/* Nav drawer */}
      {!isMobile && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.desktopDrawerOpen]: desktopDrawerOpen && !isMobile,
            [classes.drawerClose]: !desktopDrawerOpen && !isMobile
          })}
          classes={{
            paper: clsx({
              [classes.desktopDrawerOpen]: desktopDrawerOpen && !isMobile,
              [classes.drawerClose]: !desktopDrawerOpen && !isMobile
            })
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <NavRoutes />
          <Divider />
        </Drawer>
      )}
      <main className={classes.content}>{children}</main>
    </div>
  );
};
