import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React, { useReducer } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { GlobalStyles } from 'src/components/GlobalStyles';
import { routes } from 'src/routes';
import { GlobalAppContext } from './store/global-app-context';
import { initialState, reducer } from './store/reducer';
import { Typography } from './theme/typography';

export const App = () => {
  const routing = useRoutes(routes);
  const [state, dispatch] = useReducer(reducer, initialState);

  const theme: any = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: state.isDarkMode ? 'dark' : 'light',
          // background: {
          //   default: '#F4F6F8',
          //   paper: colors.common.white
          // },
          primary: {
            // contrastText: '#ffffff',
            main: '#FFB632'
          },
          secondary: {
            main: '#C6312E'
          }

          // text: {
          //   primary: '#172b4d',
          //   secondary: '#6b778c'
          // }
        },
        overrides: {
          MuiDrawer: {
            paper: {
              backgroundColor: state.isDarkMode ? '#212121' : '#fff'
            }
          },
          MuiButton: {
            label: {
              textTransform: 'none'
            }
          }
        },
        typography: Typography
      }),
    [state.isDarkMode]
  );

  return (
    <GlobalAppContext.Provider value={{ state, dispatch }}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </SnackbarProvider>
    </GlobalAppContext.Provider>
  );
};
