import { Fab, Grow, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';

export const DiscordChat = () => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = () => {
    setOpen((prev) => !prev);
  };

  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  return (
    <>
      <Fab
        aria-label="discord"
        style={{
          position: 'fixed',
          bottom: theme.spacing(isMobile ? 2 : 3),
          right: theme.spacing(isMobile ? 2 : 3),
          zIndex: 999
        }}
        color="primary"
        onClick={handleChange}
      >
        {open ? <CloseIcon /> : <ChatIcon />}
      </Fab>
      <Grow
        in={open}
        unmountOnExit={false}
        style={{
          transformOrigin: 'bottom right',
          position: 'fixed',
          zIndex: 999,
          bottom: theme.spacing(10),
          right: isMobile ? 0 : theme.spacing(10)
        }}
        {...(open ? { timeout: 1000 } : {})}
      >
        <Paper elevation={4}>
          <iframe
            title="chat iframe"
            src="https://minnit.chat/RugmeFinance?embed&&nickname="
            height={isMobile ? window.innerHeight - 200 : 600}
            width={isMobile ? window.innerWidth : 400}
            style={{ border: 'none' }}
          ></iframe>
        </Paper>
      </Grow>
    </>
  );
};
