import { Button, Container, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import React, { useContext } from 'react';
import { GlobalAppContext } from 'src/store/global-app-context';
import { ActionTypes } from 'src/store/reducer';
import { useStyles } from './LockedPage.styles';

export const LockedPage = () => {
  const { dispatch } = useContext(GlobalAppContext);
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.container}>
      <LockIcon className={classes.lockIcon} />
      <Typography color="textPrimary" variant="h2" className={classes.text}>
        Connect a wallet to view this page
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch({
            type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG,
            payload: true
          });
        }}
      >
        Connect Wallet
      </Button>
    </Container>
  );
};
