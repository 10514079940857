import { Navigate } from 'react-router-dom';
import { MainLayout } from 'src/components/MainLayout';
import { About } from 'src/pages/About';
import { Documentation } from 'src/pages/Documentation';
import { Farm } from 'src/pages/Farm';
import { Home } from 'src/pages/Home';
import { Lottery } from 'src/pages/Lottery';
import { NotFound } from 'src/pages/NotFound';

export const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/farm', element: <Farm /> },
      { path: '/about', element: <About /> },
      { path: '/documentation', element: <Documentation /> },
      { path: '/lottery', element: <Lottery /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];
