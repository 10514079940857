import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { TractorIcon } from 'src/assets/icons/Tractor';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary
  },
  listItem: {
    width: 250
  },

  navLinksContainer: {},
  navLink: {},
  logoContainer: {
    display: 'flex',
    flexGrow: 1
  }
}));

interface IRoutes {
  show: boolean;
  isExternal: boolean;
  path: string;
  name: string;
  icon: any;
}

interface INavRoutesProps {
  onClick?: any;
}

export const NavRoutes: React.FC<INavRoutesProps> = ({ onClick }) => {
  const classes = useStyles();

  const routes = [
    {
      show: true,
      isExternal: false,
      path: '/',
      name: 'Home',
      icon: <HomeIcon />
    },
    {
      show: true,
      isExternal: false,
      path: '/farm',
      name: 'Farm',
      icon: <TractorIcon />
    },
    {
      show: true,
      isExternal: false,
      path: '/lottery',
      name: 'Lottery',
      icon: <ConfirmationNumberIcon />
    },
    {
      show: true,
      isExternal: false,
      path: '/documentation',
      name: 'Documentation',
      icon: <DescriptionIcon />
    }
  ] as IRoutes[];

  return (
    <List>
      {routes.map((route: IRoutes) => {
        return (
          route.show && (
            <ListItem
              key={route.name}
              component={NavLink}
              button
              to={route.path}
              className={classes.listItem}
              onClick={onClick}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText className={classes.text} primary={route.name} />
            </ListItem>
          )
        );
      })}
    </List>
  );
};
