import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LaunchIcon from '@material-ui/icons/Launch';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import { BigNumber } from 'bignumber.js';
import { useSnackbar } from 'notistack';
import { memo, useContext, useEffect, useState } from 'react';
import ERC20 from 'src/assets/builds/erc20.abi.json';
import UniPairAbi from 'src/assets/builds/UniPair.abi.json';
import 'src/pages/styles.css';
import { GlobalAppContext } from 'src/store/global-app-context';
import { useStyles } from './PoolCard.styles';

interface IPoolCardProps {
  poolId: number;
  pool: any;
  totalAllocPoint: number;
  rugPerBlock: number;
}

export const PoolCard: React.FC<IPoolCardProps> = memo(
  ({ poolId, pool, totalAllocPoint, rugPerBlock }) => {
    const theme = useTheme();
    const { state } = useContext(GlobalAppContext);
    const [pendingRug, setPendingRug] = useState<number>(0);
    const [amount, setAmount] = useState<string>('0');
    const [display, setDisplay] = useState<string>('');
    const [APYValue, setAPYValue] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [userBalanceInWallet, setUserBalanceInWallet] = useState<string>('');
    const [userBalanceInPool, setUserBalanceInPool] = useState<string>('');
    const [symbolPair, setSymbolPair] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('sm'));

    const getPendingRug = async (live: boolean) => {
      if (live) {
        const web3 = (window as any).web3;
        // Get Pending Rug
        await state.masterRugInstance.methods
          .pendingRug(poolId, state.userAddress)
          .call()
          .then((pendingRug: number) => {
            const convertedAmount = web3.utils.fromWei(pendingRug, 'Ether');

            setPendingRug(convertedAmount);
          })
          .catch((err: any) => {
            enqueueSnackbar(
              `Error getting pending RUGME for pool ${poolId}. ${
                err.message ? err.message : err
              }`,
              {
                variant: 'error'
              }
            );
          });
      }
    };

    // Make API call right when pool is init

    useEffect(() => {
      let live = true;
      getPairNames();
      getlpTokensInPool();
      getlpTokensInWallet();
      getPendingRug(live);
      calculateAPY();
      return () => {
        live = false;
      };
    }, []);

    // Make API call on a 10 second interval
    useEffect(() => {
      let live = true;
      let interval: any;

      if (Number(userBalanceInPool) !== 0) {
        interval = setInterval(async () => {
          getPendingRug(live);
        }, 10000);
      }
      return () => {
        live = false;
        clearInterval(interval);
      };
    }, [getPendingRug, userBalanceInPool]);

    const calculateAPY = async () => {
      try {
        const web3 = (window as any).web3;
        //BLOCKS_PER_DAY varies acccording to network all values are approx and they keep changing
        //BLOCKS_PER_DAY = 21600 for Kovan Testnet
        //BLOCKS_PER_DAY = 28800 for BSC Testnet
        //BLOCKS_PER_DAY = 6400 for Ethereum Mainnet

        const BLOCKS_PER_YEAR = 6307200;

        let rewardTokenPrice = 0;
        // For Price IN ETH
        // Reward Token is Dodgecoin in our case
        rewardTokenPrice = await getRugPriceInBNB();

        // For Price in BNB
        // If you want to do calculations in BNB uncomment line below and comment line number 13
        // rewardTokenPrice = await getDodgecoinPriceInBNB()

        // REWARD_PER_BLOCK = Number of tokens your farming contract gives out per block
        const REWARD_PER_BLOCK = 10000000000000000000; // 10 * 10 ^18
        const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
          .times(REWARD_PER_BLOCK)
          .times(BLOCKS_PER_YEAR);

        // Get Total LP Tokens Deposited in Farming Contract

        // const LpTokenContract = new web3.eth.Contract(
        //   IUniswapV2Pair,
        //   LP_TOKEN_ADDRESS
        // );

        const LpTokenContract = new web3.eth.Contract(UniPairAbi, pool.lpToken);

        const totalLpDepositedInFarmingContract = await LpTokenContract.methods
          .balanceOf(state.masterRugInstance.options.address)
          .call();

        // Calculate LP Token Price
        let lpTokenPrice;
        if (pool.lpToken === '0x4A86C7462eb125d610D5a05CcFaDf26f4ED2F3e4') {
          lpTokenPrice = await getDOGCoinPrice();
        } else {
          lpTokenPrice = await calculateLpTokenPrice();
        }

        // Calculate Total Price Of LP Tokens in Contract
        const totalPriceOfLpTokensInFarmingContract = new BigNumber(
          lpTokenPrice
        ).times(totalLpDepositedInFarmingContract);

        // Calculate APY
        const apy = totalRewardPricePerYear
          .div(totalPriceOfLpTokensInFarmingContract)
          .times(100);

        // Return apy if apy is a valid number or return 0
        const value = apy.isNaN() || !apy.isFinite() ? 0 : apy.toNumber();
        setAPYValue(value);
      } catch (e) {
        console.log(e);
        setAPYValue(0);
      }
    };

    // Get RUG price in BNB
    const getRugPriceInBNB = async () => {
      try {
        const response = await fetch(
          'https://api.pancakeswap.info/api/v2/tokens/0x2400Be63aad22b332cC1371699c9802b5Ece7F7E'
        );
        const priceData = await response.json();

        return priceData.data.price_BNB; //Address of RUG on BSC Mainnet
      } catch (e) {
        console.log(e);
        return 0;
      }
    };

    // Get DOG coin price in BNB
    const getDOGCoinPrice = async () => {
      try {
        const response = await fetch(
          'https://api.pancakeswap.info/api/v2/tokens/0x4A86C7462eb125d610D5a05CcFaDf26f4ED2F3e4'
        );
        const priceData = await response.json();

        return priceData.data.price_BNB; //Address of RUG on BSC Mainnet
      } catch (e) {
        console.log(e);
        return 0;
      }
    };

    const getLpTokenReserves = async () => {
      try {
        const web3 = (window as any).web3;

        const LpTokenContract = new web3.eth.Contract(UniPairAbi, pool.lpToken);

        const totalReserves = await LpTokenContract.methods
          .getReserves()
          .call();
        // For ETH/DOGE Pool totalReserves[0] = ETH Reserve and totalReserves[1] = DOGE Reserve
        // For BNB/DOGE Pool totalReserves[0] = BNB Reserve and totalReserves[1] = DOGE Reserve
        return [totalReserves[0], totalReserves[1]];
      } catch (e) {
        console.log(e);
        return [0, 0];
      }
    };

    const getLpTokenTotalSupply = async () => {
      try {
        const web3 = (window as any).web3;
        const LpTokenContract = new web3.eth.Contract(UniPairAbi, pool.lpToken);
        const totalSupply = await LpTokenContract.methods.totalSupply().call();
        return totalSupply;
      } catch (e) {
        console.log(e);
        return 0;
      }
    };

    const calculateLpTokenPrice = async () => {
      let rewardTokenPrice = 0;
      // For Price IN ETH
      // Reward Token is Dodgecoin in our case
      // rewardTokenPrice = await getDogecoinPriceInETH();

      // For Price in BNB
      // If you want to do calculations in BNB uncomment line below and comment line number 5
      rewardTokenPrice = await getRugPriceInBNB();

      // 1 * rewardTokenPrice because 1 is the price of ETH or BNB in respective mainnet
      // This is square root of (p0 * p1) with reference to the image above
      const tokenPriceCumulative = new BigNumber(1 * rewardTokenPrice).sqrt();

      // For ETH / DOGE pair totalReserve[0] = ETH in the contract and totalReserve[1] = DOGE in the contract
      // For BNB / DOGE pair totalReserve[0] = BNB in the contract and totalReserve[1] = DOGE in the contract

      const totalReserve = await getLpTokenReserves();

      // This is square root of (r0 * r1) with reference to the image above
      const tokenReserveCumulative = new BigNumber(totalReserve[0])
        .times(totalReserve[1])
        .sqrt();

      // Total Supply of LP Tokens in the Market
      let totalSupply;

      totalSupply = await getLpTokenTotalSupply();

      // Calculate LP Token Price in accordance to the image above
      const lpTokenPrice = tokenReserveCumulative
        .times(tokenPriceCumulative)
        .times(2)
        .div(totalSupply);

      // If lpTokenPrice is a valid number return lpTokenPrice or return 0
      return lpTokenPrice.isNaN() || !lpTokenPrice.isFinite()
        ? 0
        : lpTokenPrice.toNumber();
    };

    const getPairNames = () => {
      // Dog emoji coin
      if (pool.lpToken === '0x4A86C7462eb125d610D5a05CcFaDf26f4ED2F3e4') {
        setSymbolPair('🐶');
        return;
      }
      const web3 = (window as any).web3;

      const UniPairInstance = new web3.eth.Contract(UniPairAbi, pool.lpToken);

      const p1 = new Promise((resolve, reject) =>
        UniPairInstance.methods
          .token0()
          .call()
          .then(async (result: any) => {
            const token0 = new web3.eth.Contract(ERC20, result);

            const symbol0 = await token0.methods.symbol().call();
            resolve(symbol0);
          })
          .catch((err: any) => {
            reject(err);
          })
      );
      const p2 = new Promise((resolve, reject) =>
        UniPairInstance.methods
          .token1()
          .call()
          .then(async (result: any) => {
            const token1 = new web3.eth.Contract(ERC20, result);
            const symbol1 = await token1.methods.symbol().call();
            resolve(symbol1);
          })
          .catch((err: any) => {
            reject(err);
          })
      );

      Promise.all([p1, p2])
        .then((values) => {
          setSymbolPair('[CAKE] ' + values[0] + ' / ' + values[1]);
        })
        .catch((err: any) => {
          enqueueSnackbar(
            `Error setting symbol pairs for pool ${poolId}. ${
              err.message ? err.message : err
            }`,
            {
              variant: 'error'
            }
          );
          setSymbolPair('error');
        });
    };

    const harvest = () => {
      setLoading(true);

      return state.masterRugInstance.methods
        .withdraw(poolId, 0)
        .send({ from: state.userAddress })
        .then((result: any) => {
          enqueueSnackbar(
            `Successfully harvested RUGME from pool ${poolId}. `,
            {
              variant: 'success'
            }
          );

          setLoading(false);
        })
        .catch((err: any) => {
          enqueueSnackbar(
            `Error harvesting RUGME from pool ${poolId}. ${
              err.message ? err.message : err
            }`,
            {
              variant: 'error'
            }
          );
          setLoading(false);
        });
    };

    const getlpTokensInPool = () => {
      const web3 = (window as any).web3;

      return state.masterRugInstance.methods
        .userInfo(poolId, state.userAddress)
        .call()
        .then((result: any) => {
          const convertedAmount = web3.utils.fromWei(result.amount, 'Ether');

          setUserBalanceInPool(convertedAmount);
        })
        .catch((err: any) => {});
    };

    const getlpTokensInWallet = () => {
      const web3 = (window as any).web3;

      const lpTokenInstance = new web3.eth.Contract(ERC20, pool.lpToken);

      return lpTokenInstance.methods
        .balanceOf(state.userAddress)
        .call()
        .then((result: string) => {
          const convertedAmount = web3.utils.fromWei(result, 'Ether');

          setUserBalanceInWallet(convertedAmount);
        })
        .catch((err: any) => {
          enqueueSnackbar(
            `Error getting user lp token balance for pool ${poolId}. ${
              err.message ? err.message : err
            }`,
            {
              variant: 'error'
            }
          );
        });
    };

    const deposit = () => {
      setLoading(true);
      const web3 = (window as any).web3;

      const convertedAmount = web3.utils.toWei(amount, 'Ether');

      const lpTokenInstance = new web3.eth.Contract(ERC20, pool.lpToken);

      return lpTokenInstance.methods
        .approve(state.masterRugInstance.options.address, convertedAmount)
        .send({ from: state.userAddress })
        .then((result: any) => {
          state.masterRugInstance.methods
            .deposit(poolId, convertedAmount)
            .send({ from: state.userAddress })
            .then((result: any) => {
              getlpTokensInPool();
              enqueueSnackbar(
                `Successfully deposited ${amount} RUGME into pool ${poolId}.`,
                {
                  variant: 'success'
                }
              );
              setAmount('0');
              setDisplay('');
              setLoading(false);
            })

            .catch((err: any) => {
              enqueueSnackbar(
                `Error depositing RUGME into pool ${poolId}. ${
                  err.message ? err.message : err
                }`,
                {
                  variant: 'error'
                }
              );
              setLoading(false);
            });
        })
        .catch((err: any) => {
          enqueueSnackbar(
            `Error depositing RUGME for pool ${poolId}. ${
              err.message ? err.message : err
            }`,
            {
              variant: 'error'
            }
          );
          setLoading(false);
        });
    };
    const withdraw = () => {
      setLoading(true);
      const web3 = (window as any).web3;

      const convertedAmount = web3.utils.toWei(amount, 'Ether');
      return state.masterRugInstance.methods
        .withdraw(poolId, convertedAmount)
        .send({ from: state.userAddress })
        .then((result: any) => {
          enqueueSnackbar(
            `Successfully withdrew ${amount} RUGME from pool ${poolId}. `,
            {
              variant: 'success'
            }
          );

          getlpTokensInPool();
          getPendingRug(true);

          setAmount('0');
          setDisplay('');
          setLoading(false);
        })
        .catch((err: any) => {
          enqueueSnackbar(
            `Error withdrawing RUGME from pool ${poolId}. ${
              err.message ? err.message : err
            }`,
            {
              variant: 'error'
            }
          );
          setLoading(false);
        });
    };

    const depositForm = () => (
      <Grid
        container
        direction="column"
        justify="space-around"
        style={{
          minWidth: 200
        }}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ marginBottom: theme.spacing(2) }}
        >
          Deposit LP Tokens
        </Typography>
        <TextField
          label="Amount"
          color="primary"
          value={amount}
          disabled={loading}
          variant="outlined"
          style={{ marginBottom: theme.spacing(2) }}
          inputProps={{
            min: 0
          }}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAmount(e.target.value)
          }
        />
        <Grid
          direction="row"
          container
          justify="flex-start"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() =>
              setAmount((Number(userBalanceInWallet) * 0.25).toString())
            }
          >
            25%
          </Button>
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() =>
              setAmount((Number(userBalanceInWallet) * 0.5).toString())
            }
          >
            50%
          </Button>
          <Button
            variant="text"
            color="primary"
            disableRipple
            disableFocusRipple
            disableElevation
            disableTouchRipple
            onClick={() =>
              setAmount((Number(userBalanceInWallet) * 0.75).toString())
            }
          >
            75%
          </Button>
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => setAmount(userBalanceInWallet)}
          >
            100%
          </Button>
        </Grid>
        <Grid item>
          <Grid container justify="space-between">
            <IconButton
              color="primary"
              onClick={() => setDisplay('')}
              disabled={loading}
            >
              <ChevronLeftIcon />
            </IconButton>

            <Button
              variant="contained"
              color="primary"
              disabled={Number(amount) <= 0 || loading}
              onClick={deposit}
            >
              Deposit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
    const withdrawForm = () => (
      <Grid
        container
        direction="column"
        justify="space-around"
        style={{
          minWidth: 200
        }}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ marginBottom: theme.spacing(2) }}
        >
          Withdraw LP Tokens
        </Typography>
        <TextField
          label="Amount"
          color="primary"
          value={amount}
          disabled={loading}
          variant="outlined"
          style={{ marginBottom: theme.spacing(2) }}
          inputProps={{
            min: 0
          }}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAmount(e.target.value)
          }
        />
        <Grid
          direction="row"
          container
          justify="flex-start"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() =>
              setAmount((Number(userBalanceInPool) * 0.25).toString())
            }
          >
            25%
          </Button>
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() =>
              setAmount((Number(userBalanceInPool) * 0.5).toString())
            }
          >
            50%
          </Button>
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() =>
              setAmount((Number(userBalanceInPool) * 0.75).toString())
            }
          >
            75%
          </Button>
          <Button
            variant="text"
            color="primary"
            disableElevation
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => setAmount(userBalanceInPool)}
          >
            100%
          </Button>
        </Grid>
        <Grid item>
          <Grid container justify="space-between">
            <IconButton
              color="primary"
              onClick={() => setDisplay('')}
              disabled={loading}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              disabled={Number(amount) <= 0 || loading}
              onClick={withdraw}
            >
              Withdraw
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
    const actions = () => (
      <Grid
        container
        justify="space-evenly"
        alignContent="center"
        style={{ height: '100%', alignItems: 'center', minWidth: 200 }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDisplay('withdraw')}
          >
            Withdraw
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDisplay('deposit')}
          >
            Deposit
          </Button>
        </Grid>
      </Grid>
    );
    const renderDisplay = () => {
      switch (display) {
        case '':
          return actions();

        case 'withdraw':
          return withdrawForm();

        case 'deposit':
          return depositForm();

        default:
          return actions();
      }
    };

    const determineBackground = (allocPoint: number) => {
      // Being rugged
      if (allocPoint >= 0 && allocPoint <= 10)
        // Red background
        return {
          background:
            'linear-gradient(127deg, rgba(255,50,50,0.6278886554621849) 29%, rgba(255,211,108,0.14049369747899154) 51%)',

          // Shaking animation
          animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) infinite',
          transform: 'translate3d(0, 0, 0)'
        };
      // Normal
      else if (allocPoint >= 11 && allocPoint <= 20) return;
      // Bonus
      else if (allocPoint > 20)
        // Gold background
        return {
          background:
            'linear-gradient(127deg, rgba(255,222,50,1) 0%, rgba(255,211,108,0.14049369747899154) 53%)'
        };
    };

    return (
      <Accordion
        style={{
          width: '100%',
          ...determineBackground(pool.allocPoint)
        }}
        elevation={5}
      >
        {loading && (
          <LinearProgress
            classes={{ root: classes.linearProgress }}
            color="primary"
          />
        )}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          classes={{ content: classes.accordionSummaryContent }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            <div
              className={classes.column}
              style={{
                display: 'flex',
                width: '100%',
                minWidth: 200,
                alignItems: 'center'
              }}
            >
              <Avatar
                style={{
                  backgroundColor: green[600],
                  height: 56,
                  width: 56
                }}
              >
                <PeopleIcon />
              </Avatar>
              <Typography
                color="textPrimary"
                variant="body1"
                style={{ marginLeft: theme.spacing(2) }}
              >
                {symbolPair}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end'
              }}
            >
              <div className={classes.column}>
                <Grid container direction="column">
                  <Typography color="primary" gutterBottom variant="body1">
                    Earned
                  </Typography>
                  <Typography
                    color="primary"
                    gutterBottom
                    variant="body1"
                    style={{
                      opacity: pendingRug === 0 ? '.5' : '1',
                      maxWidth: isMobile ? 70 : 150,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {pendingRug}
                  </Typography>
                </Grid>
              </div>
              <div className={classes.column}>
                <Grid container direction="column">
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    APY
                  </Typography>
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    {pool.lpToken ===
                    '0x4A86C7462eb125d610D5a05CcFaDf26f4ED2F3e4'
                      ? 'XXX'
                      : `${APYValue.toFixed(3)}%`}
                  </Typography>
                </Grid>
              </div>
            </div>
          </div>

          {!isMobile && (
            <>
              {/* Show later when we have an implementation */}
              <div className={classes.column} style={{ display: 'none' }}>
                <Grid container direction="column">
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    Liquidity
                  </Typography>
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    XXX
                  </Typography>
                </Grid>
              </div>
              {/* Show later when we have an implementation */}
              <div className={classes.column} style={{ display: 'none' }}>
                <Grid container direction="column">
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    Multiplier
                  </Typography>
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    XXX
                  </Typography>
                </Grid>
              </div>
            </>
          )}
        </AccordionSummary>

        <AccordionDetails>
          <Grid container justify="space-between" wrap="wrap">
            <Grid item className={classes.actionsContainer}>
              <>{renderDisplay()}</>
            </Grid>
            <Grid item className={classes.actionsContainer}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                style={{
                  minWidth: 200
                }}
              >
                <Grid item>
                  <Typography color="textPrimary" gutterBottom variant="body1">
                    RUGME earned
                  </Typography>
                  <Typography
                    color="primary"
                    gutterBottom
                    variant="body1"
                    style={{
                      margin: 'auto 0',
                      opacity: pendingRug === 0 ? '.5' : '1',
                      maxWidth: 150,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {pendingRug}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={Number(pendingRug) === 0 || loading}
                    onClick={harvest}
                  >
                    Harvest
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                flexGrow: 1,
                margin: theme.spacing(2)
              }}
            >
              <Button
                color="primary"
                href={`https://bscscan.com/address/${pool.lpToken}`}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<LaunchIcon />}
                variant="text"
              >
                View Contract
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);
