import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  lockIcon: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(10),
    marginBottom: theme.spacing(3)
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  }
}));
