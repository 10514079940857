import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalAppContext } from 'src/store/global-app-context';
import { PoolCard } from './PoolCard';

export const FarmLayout = () => {
  const { state } = useContext(GlobalAppContext);
  const theme = useTheme();

  const [pools, setPools] = useState<any[]>([]);
  const [rugPerBlock, setRugPerBlock] = useState<number>(0);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [totalAllocPoint, setTotalAllocPoint] = useState<number>(0);

  useEffect(() => {
    loadMasterRugData();
  }, []);

  const loadMasterRugData = async () => {
    const web3 = (window as any).web3;
    const rugPerBlock = await state.masterRugInstance.methods
      .rugPerBlock()
      .call();

    const convertedRugPerBlock = web3.utils.fromWei(rugPerBlock, 'Ether');

    setRugPerBlock(convertedRugPerBlock);

    const totalAllocPoint = await state.masterRugInstance.methods
      .totalAllocPoint()
      .call();

    setTotalAllocPoint(totalAllocPoint);

    const poolLength = await state.masterRugInstance.methods
      .poolLength()
      .call();
    const poolsInfo = [];
    for (let i = 0; i < poolLength; i++) {
      const poolInfo = await state.masterRugInstance.methods.poolInfo(i).call();
      poolsInfo.push(poolInfo);
    }

    setPools(poolsInfo);

    // const symbol = await state.rugInstance.methods.symbol().call();
    // setSymbol(symbol);

    const totalSupply = await state.rugInstance.methods.totalSupply().call();

    const convertedTotalSupply = web3.utils.fromWei(totalSupply, 'Ether');

    setTotalSupply(convertedTotalSupply);
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: `${theme.spacing(8)}px 0`,
          paddingBottom: theme.spacing(4),
          background:
            'linear-gradient(159deg, rgba(249,255,108,1) 6%, rgba(255,182,50,1) 66%)'
        }}
      >
        <Container maxWidth={'lg'}>
          <Typography
            component="h1"
            style={{ fontWeight: 500, fontSize: theme.spacing(6) }}
          >
            Farms
          </Typography>
          <Typography component="h2" variant="h3" style={{ fontWeight: 500 }}>
            Stake Liquidity Tokens (LP) to earn RUGME!
          </Typography>
          <Grid
            container
            justify="space-evenly"
            spacing={3}
            style={{ marginTop: theme.spacing(3) }}
          >
            <Grid item xs={12} sm={4} lg={3} xl={3}>
              <Card elevation={5}>
                <CardContent>
                  <Typography color="textPrimary" variant="h5">
                    Total Supply
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h3"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {totalSupply}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item xs={12} sm={4} lg={3} xl={3}>
              <Card elevation={5}>
                <CardContent>
                  <Typography color="textPrimary" variant="h5">
                    Total RUGME earned
                  </Typography>
                  <Typography color="textPrimary" variant="h3">
                    XXX
                  </Typography>
                </CardContent>
              </Card>
            </Grid> */}
            <Grid item xs={12} sm={4} lg={3} xl={3}>
              <Card elevation={5}>
                <CardContent>
                  <Typography color="textPrimary" variant="h5">
                    RUGME Per Block
                  </Typography>
                  <Typography color="textPrimary" variant="h3">
                    {rugPerBlock}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container
        maxWidth={'lg'}
        style={{
          margin: `${theme.spacing(3)}px auto`,
          height: '100%',
          paddingBottom: theme.spacing(3)
        }}
      >
        {pools &&
          pools.map((pool, idx) => (
            <PoolCard
              key={idx}
              poolId={idx}
              pool={pool}
              rugPerBlock={rugPerBlock}
              totalAllocPoint={totalAllocPoint}
            />
          ))}
      </Container>
    </>
  );
};
