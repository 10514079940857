import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { LockedPage } from 'src/components/locked-page/LockedPage';
import { LotteryLayout } from 'src/components/lottery/LotteryLayout';
import { GlobalAppContext } from 'src/store/global-app-context';

export const Lottery = () => {
  const { state } = useContext(GlobalAppContext);

  const _description = 'Test your luck with the new RUGME lottery!';

  return (
    <>
      <Helmet>
        <title>Lottery | Rugme.finance</title>
        <meta name="description" content={_description} />
      </Helmet>
      <Box
        style={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: state.isWalletConnected ? 'flex-start' : 'center'
        }}
      >
        {!state.isWalletConnected ? <LockedPage /> : <LotteryLayout />}
      </Box>
    </>
  );
};
