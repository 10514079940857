import { Box, Container, Grid, Typography, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';

// Not in use

export const About = () => {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>About | Rugme.finance</title>
      </Helmet>
      <Box
        style={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          paddingTop: theme.spacing(3)
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Typography color="textPrimary" variant="h2">
              About
            </Typography>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
